import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'
import Link from '../components/Common/Link'

import * as s from '../pages/news.module.scss'

const EvolutionConstructionDocumentManagement = () => {
  return (
    <>
      <SEO
        title="SIGNAX DOCS: the next level of construction document management"
        description="The article discusses a new tool for working with documents - SIGNAX DOCS. The author describes the main functions and capabilities of the cloud service, as well as the advantages of using it. Particular attention is paid to how SIGNAX DOCS helps improve operational efficiency and streamline construction project workflows."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          {`Introducing the evolution in construction\n management: SIGNAX DOCS`}
        </Typography>
        <Typography variant="body1">
          Welcome to the future of construction management with{' '}
          <Link href="/docs/" rel="nofollow noreferrer" target="_blank">
            SIGNAX DOCS CDE
          </Link>
          , the latest addition to our groundbreaking platform that is
          revolutionizing modern construction practices.
        </Typography>
        <Typography variant="body1">
          That’s how SIGNAX DOCS is reshaping the landscape of construction
          project management:
        </Typography>
        <Typography variant="h2" color="blue">
          Common Data Environment
        </Typography>
        <Typography variant="body1">
          SIGNAX DOCS serves as the nerve center of your project, consolidating
          all essential data under one roof. From collecting and updating
          documents to sharing and storing crucial project information, our
          platform streamlines your workflow, ensuring seamless stakeholder
          collaboration.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/evolution-construction-document-management/image-1.jpg"
            alt="Common Data Environment"
            title="Common Data Environment"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Mobile Optimization
        </Typography>
        <Typography variant="body1">
          We understand the importance of flexibility in today's fast-paced
          environment. With SIGNAX DOCS, you can review and approve documents on
          the go without compromising performance. Our platform offers full
          desktop functionality on your mobile device, ensuring that you stay
          connected and productive wherever you are.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/evolution-construction-document-management/image-2.png"
            alt="Mobile Optimization"
            title="Mobile Optimization"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Intuitive Administration
        </Typography>
        <Typography variant="body1">
          Managing project settings has never been easier with our intuitive
          admin hub. From inviting new users to monitoring service logs,
          administrators fully control project management activities. Our batch
          user addition feature further simplifies the process, allowing
          administrators to add hundreds of users with just a few clicks.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/evolution-construction-document-management/image-3.png"
            alt="Intuitive Administration"
            title="Intuitive Administration"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Robust Data Management
        </Typography>
        <Typography variant="body1">
          SIGNAX DOCS provides a pre-configured folder structure compatible with{' '}
          <Link
            href="https://www.iso.org/standard/68078.html"
            rel="nofollow noreferrer"
            target="_blank"
          >
            ISO 19650
          </Link>{' '}
          standards, ensuring consistency and compliance in data organization.
          Administrators can easily set user and role-based permissions,
          granting tailored access to project resources.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/evolution-construction-document-management/image-5.png"
            alt="Robust Data Management"
            title="Robust Data Management"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Cutting-Edge Features
        </Typography>
        <Typography variant="body1">
          From 3D Viewer powered by Autodesk Forge to PDF comparison tools,
          SIGNAX DOCS encompasses many features designed to enhance productivity
          and efficiency. Track document versions, manage issues and comments,
          and easily streamline review workflows.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/evolution-construction-document-management/image-6.png"
            alt="Cutting-Edge Features"
            title="Cutting-Edge Features"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Roadmap for the Future
        </Typography>
        <Typography variant="body1">
          Our commitment to innovation doesn't stop here. We have an extensive
          product development roadmap for 2024-2025, including desktop and
          mobile clients, a point cloud viewer, federated BIM models, and a
          Public API. We remain dedicated to meeting the evolving needs of our
          users and driving digital transformation in the construction industry.
        </Typography>
        <Typography variant="body1">
          Join us on this journey as we continue to shape the future of
          construction management.
        </Typography>
        <Typography variant="body1">
          Visit{' '}
          <Link href="/" rel="nofollow noreferrer" target="_blank">
            signax.io
          </Link>
          , our{' '}
          <Link
            href="https://wiki.signax.io/books/31-docs/page/311-signax-docs-cloud-cde"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Wiki page
          </Link>{' '}
          or for an-in-depth overview{' '}
          <Link
            href="https://youtu.be/lghToVjdrCQ"
            rel="nofollow noreferrer"
            target="_blank"
          >
            webinar “Building Tomorrow: The Power of SIGNAX DOCS in Modern
            Construction”
          </Link>{' '}
          to learn more about SIGNAX DOCS and how it can revolutionize your
          projects. Together, let's build tomorrow, today.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default EvolutionConstructionDocumentManagement
